import React from "react"
import styled from "@emotion/styled"
import { Link } from "gatsby"

const StyledNavLinks = styled.div`
  @media only screen and (max-width: 950px) {
    display: none;
  }
  display: grid;
  grid-template-columns: repeat(4, auto);
  grid-gap: 10vw;
  a {
    font-family: "Akzidenz Grotesk Extended";
    text-decoration: none;
    margin: 0;
    font-size: 1vw;
    color: #595a5c;
    color: ${props =>
      props.location.pathname === "/services" ? "white" : "#595a5c"};
  }
  .active {
    font-weight: bold;
    border-bottom: solid 2px #595a5c;
  }
  .active-service {
    font-weight: bold;
    border-bottom: solid 2px white;
  }
`

const NavItems = styled.div`
  color: black;
`

const NavLinks = ({ location }) => {
  return (
    <StyledNavLinks location={location}>
      <NavItems>
        <Link to="/about" activeClassName="active">
          About
        </Link>
      </NavItems>
      <NavItems>
        <Link to="/services" activeClassName="active-service">
          Services
        </Link>
      </NavItems>
      <NavItems>
        <Link to="/portfolio" activeClassName="active" className="portfolio">
          Portfolio
        </Link>
      </NavItems>
      <NavItems>
        <Link to="/contact" activeClassName="active" className="contact">
          Contact
        </Link>
      </NavItems>
    </StyledNavLinks>
  )
}

export default NavLinks
