/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { useMediaQuery } from "react-responsive"

import Header from "./Header"
import HeaderMobile from "./HeaderMobile"
import { GlobalStyle } from "./GlobalStyle"
import Footer from "./Footer"
import FooterMobile from "./FooterMobile"

const Layout = ({ children, location }) => {
  const isDesktop = useMediaQuery({
    query: "(min-width: 900px)",
  })
  const isMobile = useMediaQuery({
    query: "(max-width: 900px)",
  })
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <GlobalStyle />
      {isDesktop && (
        <Header siteTitle={data.site.siteMetadata.title} location={location} />
      )}
      {isMobile && (
        <HeaderMobile
          siteTitle={data.site.siteMetadata.title}
          location={location}
        />
      )}
      <div style={{}}>
        <main>{children}</main>
        {isDesktop && <Footer />}
        {isMobile && <FooterMobile />}
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
