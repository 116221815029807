import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"
import styled from "@emotion/styled"
import { useMediaQuery } from "react-responsive"

import bmrLogo from "../../images/bmr-logo.svg"
import NavMenu from "./NavMenu"
import { Hamburger } from "./Hamburger"
import NavLinks from "./NavLinks"

const StyledHeader = styled.header`
  background: transparent;
  background: ${props => props.location.pathname === "/services" && "black"};
  background: ${props => props.location.pathname === "/about" && "white"};
  background: ${props => props.location.pathname === "/portfolio" && "white"};
  background: ${props => props.location.pathname === "/contact" && "white"};

  background: ${props => props.open && "black"};
  position: fixed;
  z-index: 10;
  padding: 0 5vw;
  padding-right: 7.5vw;
  @media only screen and (max-width: 900px) {
    padding: 0 1rem;
    padding-right: 1rem;
  }
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  .header-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
  .logo {
    width: 10vw;
    transform: translateY(3px);
    @media screen and (max-width: 900px) {
      width: 120px;
    }
    img {
      margin: 0;
    }
  }
  .burger-wrapper {
    transform: translateY(4px);
  }
`

const Header = ({ location }) => {
  const [open, setOpen] = useState(false)
  const [top, setTop] = useState(true)
  const onOpen = () => {
    if (open) {
      setOpen(false)
    } else {
      setOpen(true)
    }
  }

  useEffect(() => {
    const listenScrollEvent = () => {
      if (window.scrollY > 35) {
        setTop(false)
      } else {
        setTop(true)
      }
    }
    window.addEventListener("scroll", listenScrollEvent)

    return function cleanup() {
      window.removeEventListener("scroll", listenScrollEvent)
    }
  })
  return (
    <StyledHeader location={location} open={open} top={top}>
      <div className="header-content">
        <div>
          <div className="logo">
            {location.pathname === "/" ? (
              <img src={bmrLogo} alt="bmr logo"></img>
            ) : (
              <Link to={`${location.pathname === "/" ? "#splash" : "/"}`}>
                <img src={bmrLogo} alt="bmr logo"></img>
              </Link>
            )}
          </div>
        </div>
        <div>
          {location.hash === "#splash" || location.hash === "" ? (
            <NavLinks location={location} />
          ) : (
            <div className="burger-wrapper">
              <Hamburger isOpen={open} onOpen={onOpen} color="darkgrey" />
            </div>
          )}
        </div>
      </div>
      {open ? (
        <NavMenu isOpen={open} onOpen={onOpen} location={location} />
      ) : null}
    </StyledHeader>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
