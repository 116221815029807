import React from "react"
import styled from "@emotion/styled"

import instagram from "../../../images/instagram.svg"
import linkedin from "../../../images/linkedin.svg"
import facebook from "../../../images/facebook.svg"

const StyledSocialMediaLinks = styled.div`
  display: grid;
  grid-template-columns: 20px 20px 20px 20px;
  justify-content: space-between;
  img {
    width: 20px;
    height: 20px;
  }
`

const SocialMediaLinks = () => (
  <StyledSocialMediaLinks>
    <img src={instagram} alt="instagram" />
    <img src={linkedin} alt="linkedin" />
    <img src={facebook} alt="facebook" />
  </StyledSocialMediaLinks>
)

export default SocialMediaLinks
