import React, { useState } from "react"
import styled from "@emotion/styled"
import { Link } from "gatsby"

const StyledInformation = styled.div`
  border-right: 1px solid #a6a8ab;
  .all-projects-wrapper {
    margin-bottom: 5vw;
    .all-projects {
      color: #6d6e71;
      font-size: 1.1vw;
      margin: 0;
      font-weight: 400;
    }
  }
  .details-wrapper {
    padding-right: 5vw;
    .details-title {
      h1 {
        font-size: 2vw;
        font-weight: 400;
        margin-bottom: 2vw;
      }
    }
    .details {
      display: grid;
      grid-template-columns: 1fr 1fr;
      .item {
        margin-bottom: 2vw;
        font-family: "Akzidenz Grotesk Extended";
        color: #58595b;
        .item-heading {
          font-weight: 600;
        }
        .item-description {
        }
      }
    }
  }
`

const Information = props => {
  return (
    <StyledInformation>
      <div className="information">
        <div className="all-projects-wrapper">
          <Link to="/portfolio" style={{ textDecoration: "none" }}>
            <h1 className="all-projects">ALL PROJECTS</h1>
          </Link>
        </div>
        <div className="details-wrapper">
          <div className="details-title">
            <h1>{props.title}</h1>
          </div>
          <div className="details">
            <div className="item">
              <div className="item-heading">Role</div>
              <div className="item-description">{props.role}</div>
            </div>
            <div className="item">
              <div className="item-heading">Client</div>
              <div className="item-description">{props.client}</div>
            </div>
            <div className="item">
              <div className="item-heading">Categories</div>
              <div className="item-description">{props.categories}</div>
            </div>
          </div>
        </div>
      </div>
    </StyledInformation>
  )
}

export default Information
