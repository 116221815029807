import React from "react"
import styled from "@emotion/styled"
import { Link } from "gatsby"

import instagram from "../../../images/instagram.svg"
import facebook from "../../../images/facebook.svg"
import linkedin from "../../../images/linkedin.svg"

const StyledBurgerLinks = styled.div`
  position: relative;
  height: 60%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  line-height: 4rem;
  font-weight: 600;
  text-decoration: none;
  position: relative;
  z-index: 1;
  top: 50%;
  transform: translateY(-60%);
  text-align: center;
  font-size: 1.5rem;
  a {
    text-decoration: none;
    font-family: "Akzidenz Grotesk Extended";
    font-weight: 400;
    color: white;
  }
  a:visited {
    text-decoration: none;
    color: white;
  }
  a:hover {
    text-decoration: none;
    color: white;
  }
  a:focus {
    text-decoration: none;
    color: white;
  }
  a:hover,
  a:active {
    text-decoration: none;
    color: white;
  }
  .social-media {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    width: 15vw;
    margin: 0 auto;
    justify-items: center;
    img {
      width: 2.5vw;
      max-height: 2.5vw;
      margin: 0;
    }
  }
`

const activeStyle = {
  color: "white",
  textDecoration: "underline",
}

const BurgerLinks = () => (
  <StyledBurgerLinks className="links-container">
    <div>
      <div></div>
      <div>
        <Link
          className="link"
          to="/about"
          activeStyle={activeStyle}
          data-cy="navlink-tentang"
        >
          About
        </Link>
      </div>
      <div>
        <Link to="/services" activeStyle={activeStyle}>
          Services
        </Link>
      </div>
      <div>
        <Link to="/portfolio" activeStyle={activeStyle}>
          Portfolio
        </Link>
      </div>
      <div>
        <Link to="/contact" activeStyle={activeStyle}>
          Contact
        </Link>
      </div>
    </div>
    <div className="social-media">
      <img src={instagram} />
      <img src={facebook} />
      <img src={linkedin} />
    </div>
  </StyledBurgerLinks>
)

export default BurgerLinks
