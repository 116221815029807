import React from "react"
import styled from "@emotion/styled"

const StyledHamburger = styled.div`
  .hamburger {
    &:focus {
      outline: 0;
    }
    padding: 0.5rem;
    display: inline-block;
    cursor: pointer;
    transition-property: opacity, filter;
    transition-duration: 0.15s;
    transition-timing-function: linear;
    font: inherit;
    color: white;
    text-transform: none;
    background-color: transparent;
    border: 0;
    margin: 0;
    overflow: visible;
    transform: translateY(-3px);
  }
  .hamburger.active .hamburger-inner,
  .hamburger.active .hamburger-inner::before,
  .hamburger.active .hamburger-inner::after {
    background-color: ${props => props.color};
  }

  .hamburger-box {
    width: 40px;
    height: 24px;
    display: inline-block;
    position: relative;
  }

  .hamburger-inner {
    display: block;
    top: 50%;
    margin-top: -0px;
  }
  .hamburger-inner,
  .hamburger-inner::before,
  .hamburger-inner::after {
    width: 32px;
    height: 3px;
    background-color: ${props => props.color};
    border-radius: 4px;
    position: absolute;
    transition-property: transform;
  }
  .hamburger-inner::before,
  .hamburger-inner::after {
    content: "";
    display: block;
  }
  .hamburger-inner::before {
    top: -10px;
  }
  .hamburger-inner::after {
    bottom: -10px;
  }

  .hamburger--spring .hamburger-inner {
    top: 3px;
    /* transition: background-color 0s 0.13s linear; */
  }
  .hamburger--spring .hamburger-inner::before {
    top: 9px;
    transition: top 0.1s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1),
      transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  .hamburger--spring .hamburger-inner::after {
    top: 18px;
    transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1),
      transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }

  .hamburger--spring.active .hamburger-inner {
    transition-delay: 0.22s;
    background-color: transparent !important;
  }
  .hamburger--spring.active .hamburger-inner::before {
    top: 0;
    transition: top 0.1s 0.15s cubic-bezier(0.33333, 0, 0.66667, 0.33333),
      transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: translate3d(0, 10px, 0) rotate(45deg);
  }
  .hamburger--spring.active .hamburger-inner::after {
    top: 0;
    transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333),
      transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: translate3d(0, 10px, 0) rotate(-45deg);
  }
`

export const Hamburger = props => {
  const onOpen = () => props.onOpen()

  return (
    <StyledHamburger color={props.color}>
      <button
        className={`hamburger hamburger--spring ${
          props.isOpen ? "active" : ""
        }`}
        onClick={onOpen}
        type="button"
        aria-label="menu"
      >
        <span className="hamburger-box">
          <span className="hamburger-inner" />
        </span>
      </button>
    </StyledHamburger>
  )
}
