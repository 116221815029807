import React from "react"
import styled from "@emotion/styled"

import BurgerLinks from "../BurgerLinks"

const StyledNavMenu = styled.div`
  position: fixed;
  z-index: 100;
  background: black;
  color: white;
  width: 100%;
  height: 100vh;
  top: 100px;
  left: 0;
  -webkit-animation: "fade-in" 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: "fade-in" 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  @keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`

const NavMenu = ({ location }) => {
  return (
    <StyledNavMenu>
      <BurgerLinks location={location} />
    </StyledNavMenu>
  )
}

export default NavMenu
