import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"
import styled from "@emotion/styled"

import { Hamburger } from "./Hamburger"
import NavMenu from "./NavMenu"
import bmrLogo from "../../images/bmr-logo.svg"

const StyledHeader = styled.header`
  background: transparent;
  background: ${props => props.location.pathname === "/services" && "black"};
  background: ${props => props.location.pathname === "/about" && "white"};
  background: ${props => props.location.pathname === "/portfolio" && "white"};
  background: ${props => props.location.pathname === "/contact" && "white"};
  background: ${props =>
    props.location.pathname === "/" && !props.top && "white"};
  background: ${props => props.open && "black"};
  width: 100%;
  position: fixed;
  z-index: 10;
  box-sizing: border-box;
  transition: background 0.3s;
  .header-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 20px 20px 20px;
    .logo {
      img {
        margin: 0;
        width: 100px;
      }
    }
    .burger {
      img {
        margin: 0%;
        width: 30px;
      }
    }
  }
`

const Header = ({ location }) => {
  const [open, setOpen] = useState(false)
  const [top, setTop] = useState(true)
  const onOpen = () => {
    if (open) {
      setOpen(false)
    } else {
      setOpen(true)
    }
  }

  useEffect(() => {
    const listenScrollEvent = () => {
      if (window.scrollY > 35) {
        setTop(false)
      } else {
        setTop(true)
      }
    }
    window.addEventListener("scroll", listenScrollEvent)
    return function cleanup() {
      window.removeEventListener("scroll", listenScrollEvent)
    }
  })

  return (
    <StyledHeader location={location} open={open} top={top}>
      <div className="header-content">
        <div className="logo">
          <Link to="/">
            <img src={bmrLogo} alt="bmr logo"></img>
          </Link>
        </div>
        <div className="burger-wrapper">
          <Hamburger isOpen={open} onOpen={onOpen} color="darkgrey" />
        </div>
      </div>
      {open ? <NavMenu isOpen={open} onOpen={onOpen} /> : null}
    </StyledHeader>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
