import React from "react"
import styled from "@emotion/styled"
import { Link } from "gatsby"

const StyledNavLinks = styled.div`
  position: relative;
  height: 60%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  line-height: 4rem;
  font-weight: 600;
  text-decoration: none;
  position: relative;
  z-index: 1;
  top: 50%;
  transform: translateY(-75%);
  text-align: center;
  font-size: 1.5rem;
  color: white;
  a {
    text-decoration: none;
    font-family: "Akzidenz Grotesk Extended";
    font-weight: 400;
    color: white;
  }
  a:visited {
    text-decoration: none;
    color: white;
  }
  a:hover {
    text-decoration: none;
    color: white;
  }
  a:focus {
    text-decoration: none;
    color: white;
  }
  a:hover,
  a:active {
    text-decoration: none;
    color: white;
  }
`

const activeStyle = {
  color: "white",
  textDecoration: "underline",
}

const NavLinks = () => (
  <StyledNavLinks className="links-container">
    <div>
      <Link
        className="link"
        to="/about"
        activeStyle={activeStyle}
        data-cy="navlink-tentang"
      >
        About
      </Link>
    </div>
    <div>
      <Link to="/services" activeStyle={activeStyle}>
        Services
      </Link>
    </div>
    <div>
      <Link to="/portfolio" activeStyle={activeStyle}>
        Portfolio
      </Link>
    </div>
    <div>
      <Link to="/contact" activeStyle={activeStyle}>
        Contact
      </Link>
    </div>
  </StyledNavLinks>
)

export default NavLinks
