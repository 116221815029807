import React from "react"
import styled from "@emotion/styled"

import logo from "../../images/footer-logo.svg"
import { Link } from "gatsby"
import SocialMediaLinks from "./SocialMediaLinks"

const StyledFooterMobile = styled.footer`
  p {
    margin: 0;
  }
  a {
    text-decoration: none;
    color: white;
  }
  img {
    margin: 0;
  }
  position: relative;
  background: rgb(237, 237, 237);
  background: linear-gradient(
    90deg,
    rgba(237, 237, 237, 1) 0%,
    rgba(180, 180, 180, 1) 7%,
    rgba(142, 142, 142, 1) 18%,
    rgba(105, 105, 105, 1) 27%,
    rgba(15, 15, 15, 1) 96%
  );
  font-family: "Akzidenz Grotesk Extended";
  color: white;
  .footer-content {
    margin: 0 auto;
    max-width: 1248px;
    height: 100%;
    padding: 2rem 2rem;
    line-height: 1.1rem;
    .one {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      font-size: 0.8rem;
      margin-bottom: 1rem;
      .logo {
        img {
          width: 140px;
          margin-bottom: 1rem;
        }
      }
      .pt {
      }
    }
    .two {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      font-size: 0.7rem;
      margin-bottom: 3rem;
    }
    .three {
      margin-bottom: 3rem;
      .footer-links {
        display: grid;
        grid-template-rows: 1fr 1fr 1fr 1fr;
        grid-gap: 2rem;
        font-size: 0.9rem;
        text-align: center;
      }
    }
    .four {
    }
  }
`

const FooterMobile = () => {
  return (
    <StyledFooterMobile>
      <div className="footer-content">
        <div className="one">
          <div className="logo">
            <img src={logo} alt="footer-logo" />
          </div>
          <div className="pt">
            <p>PT Bahtera Mitra Rajawali</p>
          </div>
        </div>
        <div className="two">
          <div className="address">
            <p>
              <b>Head Office & Factory</b>
            </p>
            <p>Taman Tekno Blok J2/20 BSD, Tangerang</p>
          </div>
          <div className="contacts">
            <p>
              <b>Phone</b> 021 7587 4672, 021 7563968/78
            </p>
            <p>
              <b>Fax</b> 021 7587 4673
            </p>
            <p>
              <b>Email</b> marketing@bmrlabel.com
            </p>
          </div>
        </div>

        <div className="three">
          <div className="footer-links">
            <Link to="/about">ABOUT</Link>
            <Link to="/services">SERVICES</Link>
            <Link to="/portfolio">PORTFOLIO</Link>
            <Link to="/contact">CONTACT</Link>
          </div>
        </div>
        <div className="four">
          <SocialMediaLinks />
        </div>
      </div>
    </StyledFooterMobile>
  )
}

export default FooterMobile
